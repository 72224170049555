import React from 'react'
import PropTypes from 'prop-types';

export default function Button(props) {
    let { loading, loadtext, children, ...rest } = props
    return (
        <button disabled={loading} {...rest}>{loading ? loadtext : children}</button>
    )
}

Button.defaultProps = {
    loading: false,
    loadtext: 'กำลังส่งข้อมูล',
}

Button.propTypes = {
    loading: PropTypes.bool,
    loadtext: PropTypes.string,
}
