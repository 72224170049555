import React from "react";
import { Route } from 'react-router-dom'
import { Navbar } from 'components'

const PublicRoute = ({
    component: Component,
    nofooter,
    noNavbar,
    ...rest
}) => {
    return (
        <Route {...rest} render={props => noNavbar ? <Component {...props} /> : <Navbar footer={nofooter}><Component {...props} /></Navbar>} />
    );
};

export default PublicRoute;