import React from 'react';
import PropTypes from 'prop-types';
import './cardtable.scss'

export function CardTable(props) {
    let { children, classTable, ...rest } = props
    return (
        <div {...rest}>
            <div className={`card-table ${classTable}`}>
                {children}
            </div>
        </div>
    )
}
CardTable.defaultProps = {
    classTable: ''
}

CardTable.propTypes = {
    classTable: PropTypes.string

}
export function CardRow(props) {
    let { children, classRow } = props
    return (
        <div className={`row -cardrow ${classRow}`}>
            {children}
        </div>
    )
}
CardRow.defaultProps = {
    classRow: ''
}

CardRow.propTypes = {
    classRow: PropTypes.string

}
export function CardColumn(props) {
    let { children, classColumn, title } = props
    return (
        <div className={`col -column ${classColumn}`}>
            <small>{title}</small>
            <div>{children}</div>
        </div>
    )

}
CardColumn.defaultProps = {
    classColumn: '',
    title: ''
}

CardColumn.propTypes = {
    classColumn: PropTypes.string,
    title: PropTypes.string
}