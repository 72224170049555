import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './footer.scss'
import { params } from 'utils'

export default function Footer() {
    return (
        <footer className="footer">
            <Row>
                <Col xs={6} sm={6} xl={3} >
                    <h4 className="mt-3">Page</h4>

                    <Row>
                        <Col xl={6}>
                            <a href={"/home" + params}>
                                Home
                             </a>

                        </Col>
                        <Col xl={6}>
                            <a href={"/broker" + params}>
                                EA
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <a href={"/about" + params}>
                                About
                            </a>
                        </Col>
                        <Col xl={6}>

                            <a href={"/cash-back" + params}>
                                Cash Back
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <a href={"/social-trade" + params}>
                                Social Trade
                            </a>
                        </Col>
                        <Col xl={6}>

                            <a href={"/community" + params}>
                                Community
                           </a>
                        </Col>
                    </Row>



                    <div>

                        <a href={"/broker" + params}>
                            Broker
                           </a>
                    </div>
                </Col>
                <Col xs={6} sm={6} xl={3}>
                    <h4 className="mt-3">Support</h4>
                    <a>
                        Help Center
                    </a>
                </Col>
                <Col className="" xs={6} sm={6} xl={3}>
                    <h4 className="mt-3">Service</h4>
                    <Row>
                        <Col xl={6}>

                            <a href={"/social-trade" + params}>
                                Copy Trade
                    </a>
                        </Col>
                        <Col xl={6}>

                            <a href={"/home" + params}>
                                Product FX Society
                    </a>
                        </Col>



                    </Row>

                    <Row>
                        <Col xl={6}>
                            <a href={"/ea" + params}>
                                EA Robot
                        </a>
                        </Col>
                        <Col xl={6}>
                            <a href={"/home" + params}>
                                FX Society
                          </a>
                        </Col>

                    </Row>
                    <div>
                        <a href={"/cash-back" + params}>
                            Cash Back
                    </a>

                    </div>
                    <div>
                        <a href={"/broker" + params}>
                            Affiliate IB
                        </a>
                   </div>
                    <div>
                        <a href={"/ea_dashboard" + params}>
                            Dashboard Fx
                        </a>
                    </div>
                    <div>
                        <a href={"/vps" + params}>
                            VPS
                        </a>
                    </div>
                </Col>
                <Col className="" xs={6} sm={6} xl={3}>
                    <h4 className="mt-3 contact-media">Contact</h4>
                    <div className="d-flex contact-media">

                        <a className="facebook" href="https://www.facebook.com/Fxsociety2020" target="_blank" rel="noopener noreferrer"><img src={require('../../icons/facebook.png')} alt="logo" /></a>
                        <a className="facebook" href="https://www.facebook.com/Fxsociety2020" target="_blank" rel="noopener noreferrer"><img src={require('../../icons/line.png')} alt="logo" /></a>
                        <a className="facebook" href="https://www.facebook.com/Fxsociety2020" target="_blank" rel="noopener noreferrer"><img src={require('../../icons/call.png')} alt="logo" /></a>

                    </div>
                    <div className="contact-media">
                        <span className="terms">Terms of</span>
                        <span className="pavicy ">
                            Privacy
                        </span>
                    </div>

                </Col>
            </Row>
            <hr className="hr" />
            <div className="footer-down ">
                <div className="-left">
                    <img src={require('../../images/logo.png')} alt="logo" />
                </div>
                <div className="-right">
                    C2020@FX Society.Allright Reserved.
                 </div>
            </div>


        </footer >
    )
}