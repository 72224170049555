import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

export default class Template1 extends Component {
    render() {
        return (
            <div style={{ padding: '100px'}}>
                {/*  */}
                <Row>
                    <Col>
                        <div className="panel">
                            <div className="title">default panel</div>
                            <div className="content">
                                default panel
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="panel _shadow">
                            <div className="title">panel add shadow</div>
                            <div className="content">
                                panel add shadow
                            </div>
                        </div>
                    </Col>
                </Row>
                {/*  */}
                <div className="panel">
                    <div className="title">default panel</div>
                    <div className="content">
                        {/*  */}
                        <Row>
                            <Col>
                                <div className="panel _shadow _radius">
                                    <div className="title">panel add radius</div>
                                    <div className="content">
                                        panel add radius
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="panel _shadow">
                                    <div className="title">panel default radius</div>
                                    <div className="content">
                                        panel default radius
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/*  */}
                        <Row>
                            <Col>
                                <div className="panel _shadow _radius">
                                    <div className="title _left">panel add radius</div>
                                    <div className="content">
                                        panel big title
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="panel _shadow">
                                    <div className="title _center">panel big title</div>
                                    <div className="content">
                                        panel big title
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="panel _shadow">
                                    <div className="title _right">panel big title</div>
                                    <div className="content">
                                        panel big title
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/*  */}
                        <div className="panel _shadow">
                            <div className="content">
                                no title
                            </div>
                        </div>
                        {/*  */}
                        <div className="panel _add_padding _shadow">
                            <div className="title">panel add padding</div>
                            <hr className="line_bottom" />
                            <div className="content">
                                panel add padding add line
                            </div>
                        </div>
                        {/*  */}
                        <div className="panel _shadow">
                            <div className="title _big">panel big title</div>
                            <div className="content">
                                panel big title
                            </div>
                        </div>
                        {/*  */}
                    </div>
                </div>




            </div>
        )
    }
}
