import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

export default class template2 extends Component {
    render() {
        return (
            <div style={{ padding: '100px'}}>
                <Row className="my-5">
                    <Col><button className="btn btn-border-orange">btn-border-orange</button></Col>
                    <Col><button className="btn btn-border-sky">btn-border-sky</button></Col>
                    <Col><button className="btn btn-border-blue">btn-border-blue</button></Col>
                    <Col><button className="btn btn-border-gray">btn btn-border-gray</button></Col>
                    <Col><button className="btn btn-border-red">btn-border-red</button></Col>
                </Row>
                <Row className="my-5">
                    <Col><button className="btn btn-orange">btn-white</button></Col>
                    <Col><button className="btn btn-sky">btn-white</button></Col>
                    <Col><button className="btn btn-blue">btn-white</button></Col>
                    <Col><button className="btn btn-gray">btn-white</button></Col>
                    <Col><button className="btn btn-red">btn-red</button></Col>
                </Row>
                <Row className="my-5">
                    <Col><button className="btn btn-orange btn-radius">btn-white</button></Col>
                    <Col><button className="btn btn-sky btn-radius">btn-white</button></Col>
                    <Col><button className="btn btn-blue btn-radius">btn-white</button></Col>
                    <Col><button className="btn btn-gray btn-radius">btn-white</button></Col>
                    <Col><button className="btn btn-red btn-radius">btn-white</button></Col>
                </Row>
                <Row className="my-5">
                    <Col><button className="btn btn-orange btn-bigger">btn-white</button></Col>
                    <Col><button className="btn btn-sky btn-bigger">btn-white</button></Col>
                    <Col><button className="btn btn-blue btn-bigger">btn-white</button></Col>
                    <Col><button className="btn btn-gray btn-bigger">btn-white</button></Col>
                    <Col><button className="btn btn-red btn-bigger">btn-red</button></Col>
                </Row>
                <Row className="my-5">
                    <Col><button className="btn btn-orange btn-mw-100">btn-mw-100</button></Col>
                    <Col><button className="btn btn-sky btn-mw-150">btn-mw-150</button></Col>
                    <Col><button className="btn btn-blue btn-mw-200">btn-mw-200</button></Col>
                    <Col><button className="btn btn-gray btn-mw-250">btn-mw-250</button></Col>
                    <Col><button className="btn btn-red btn-mw-300">btn-mw-300</button></Col>
                </Row>
                <Row className="my-5">
                    <Col><h1 className="text-gray">text-gray</h1></Col>
                    <Col><h1 className="text-orange">text-orange</h1></Col>
                    <Col><h1 className="text-sky">text-sky</h1></Col>
                    <Col><h1 className="text-blue">text-blue</h1></Col>
                    <Col><h1 className="text-red">text-red</h1></Col>
                </Row>
                <Row className="my-5">
                    <Col><div className="border-gray">border-red</div></Col>
                    <Col><div className="border-orange">border-red</div></Col>
                    <Col><div className="border-sky">border-red</div></Col>
                    <Col><div className="border-blue">border-red</div></Col>
                    <Col><div className="border-red">border-red</div></Col>
                </Row>
            </div>
        )
    }
}
