import React, { Component } from 'react'

export default class Unauthorize extends Component {
    render() {
        return (
            <div>
                <div>
                    <h4>401</h4>
                    <div>ไม่สามารถใช้งานได้</div>
                </div>
            </div>
        )
    }
}
