
function getparams() {
    let params = {}
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => { return params[key] = value; });
    return params

}
export const getallparams = getparams()
export const params = window.location.search
export const LinkTo = (link) => {
    return window.location.assign(link + params)
}