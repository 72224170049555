import React, { Component } from 'react'
import { Row, Col, Button, Form, Modal, FormGroup } from 'react-bootstrap';
import { POST, GET } from 'api';
import Swal from 'sweetalert2'
export default class resetpassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            new_password: '',
            confirm_password: ''

        }
    }
    onChangeText = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
        console.log('name', name, value)
    }
    resetpassword = async () => {
        const { new_password, confirm_password } = this.state
        if (new_password === confirm_password) {
            try {
                let result = await POST('/authen/reset_password', { token: this.props.match.params.token, password: new_password })
                console.log('password', result)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Reset Password success',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.href = "/login"
            }
            catch (error) {
                // console.log('loginFunc error', error)
                // alert(error.massage)
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: error.message,
                    showConfirmButton: true,

                })
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Password is not equal',
                showConfirmButton: true,

            })

        }

    }


    render() {
        return (
            <div>
                <div className="header">
                    <div className="background">
                        <img src={require('../expertadvisor/img/bgEA.png')} alt="" />
                    </div>


                </div>
                <div className="min-vh-100 " style={{ margin: '80px 10px' }} >
                    <div className="container">
                        <Row>

                            <div className="panel _shadow _radius" style={{ width: '800px' }}>
                                <h2 className="text-center" style={{ paddingTop: '50px' }}>Reset your password</h2>
                                <p className="text-center">Please enter and confirm your new password bellow to access your account</p>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Form  >
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={12} >
                                                New password
			 				             </Form.Label>

                                            <Form.Control
                                                type="password"
                                                style={{ width: '100%' }}
                                                name="new_password"
                                                className="input-text"
                                                placeholder="New password"
                                                onChange={this.onChangeText}
                                            />

                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={12} >
                                                Confirm password
			 				</Form.Label>
                                            <Form.Control
                                                type="password"
                                                style={{ width: '100%' }}
                                                name="confirm_password"
                                                className="input-text"
                                                placeholder="Confirm password"
                                                onChange={this.onChangeText}
                                            />

                                        </Form.Group>
                                    </Form>

                                </div>
                                <div className="d-flex" style={{ width: '100%', justifyContent: 'center', marginTop: '30px' }}>
                                    <button className="btn btn-green" onClick={this.resetpassword}>
                                        Reset Password
                        </button>
                                </div>


                            </div>
                        </Row>

                    </div>

                </div>


            </div>
        )
    }
}
