import React, { Component } from 'react'
import '../../../styles/ea.scss'
import { Form, InputGroup, Row, Col } from 'react-bootstrap'
export default class EmailInput extends Component {
    render() {
        return (
            <div className="ea header_content">
                <div className="header">

                </div>
                <div className="container">
                    <div className="panel _shadow _radius h-100">
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <InputGroup.Prepend >
                                    <InputGroup.Text>
                                        <i className="far fa-envelope"></i>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <Form.Control
                                    type="text"
                                    name="user_name"
                                    placeholder="Email"
                                // onChange={this.onChangeText}
                                />
                            </InputGroup>
                        </Form.Group>


                    </div>

                </div>
            </div>

        )
    }
}
