import React, { Component } from 'react'
import { NavLink } from 'utils'
// icons
import SaveIcon from "@material-ui/icons/Save";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { POST, GET } from 'api';


export default class Sidebar extends Component {

    async logout() {
        try {
            // alert('log')
            await GET('/authen/logout')
            console.log('logout')
            window.localStorage.setItem('login', '')
            window.location.assign('/')
        } catch (error) {
            // alert('logout')
        }

        // signOut
    }

    render() {
        return (
            <div className="sidebar">
                <div className="logo">
                    {/* <img src={require('./society-01@2x.png')} alt="logo" /> */}
                </div>
                <ul className="menu">
                    <li className="link">
                        <NavLink to="/" classActive="active" tag="template1">Template 1</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/temp2" classActive="active" tag="temp2">Template 2</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/temp3" classActive="active" tag="temp3">Template 3</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/dashboard" classActive="active" tag="temp3"><DashboardIcon />Dashboard</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/course" classActive="active" tag="temp3"><VideoLibraryIcon />Course</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/copytrade" classActive="active" tag="temp3"><FileCopyIcon />Copy Trade</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/expert-advisor" classActive="active" tag="temp3"><SaveIcon />EA</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/signal" classActive="active" tag="temp3"><TrendingUpIcon />Signal</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/affiliate" classActive="active" tag="temp3"><RecordVoiceOverIcon />Affiliate IB</NavLink>
                    </li>
                    <li className="link">
                        <NavLink to="/support" classActive="active" tag="temp3"><LiveHelpIcon />Support</NavLink>
                    </li>
                    <li className="link">
                        <NavLink onClick={() => this.logout()} classActive="active" tag="temp3"><ExitToAppIcon />Logout</NavLink>
                    </li>
                </ul>
            </div>
        )
    }
}
