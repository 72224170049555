import React, { Component } from 'react'
import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import { POST, GET } from 'api';
import Swal from 'sweetalert2'

const popup = (text) => {
    Swal.fire({
        icon: 'warning',
        title: text,
        showConfirmButton: false,
        timer: 1500
    })
}
export default class verification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idcard: '',
            bookbank_no: '',
            bookbankname: '',
            file_pic_idcard: '',
            file_pic_bookbank: '',
            bank_person_name: '',
            disabled: true,
            checkverification: false,
            phone: '',
            status: 'loading'


        };
    }
    componentDidMount() {

        this.verify_info();


    }
    onChangeText = ({ target: { value, name } }) => {
        this.setState({ [name]: value });
    };

    handleInputChange = ({ target: { checked, name } }) => {
        this.setState({ [name]: checked })
    }
    verify_info = async () => {
        try {
            let result = await GET('/user/verify_info');
            this.setState({ verify_info: result });

        } catch (error) {

            console.log('error', error);
        }
    };
    onsave = async () => {
        const state = this.state
        let { idcard, status, verify_info, bookbank_no, bank_name ,bookbankname, file_pic_idcard, file_pic_bookbank, bank_person_name, checkverification, phone } = this.state
        // console.log('check', checkverification)
        if (!checkverification) {
            popup('กรุณายอมรับเงื่อนไข')

        }
        // else if (this.state.idcard === this.state.verify_info.id_card) {
        //     popup('เลขบัตรประชาชนนี้ได้ถูกใช้แล้ว')

        // }
        else {
            console.log(this.state)

            if(idcard == "" || bookbank_no == "" || bank_name == "" || bank_person_name== "" || phone == "" || file_pic_bookbank == "" || file_pic_idcard == ""){
                popup('กรุณากรอกข้อมูลให้ครบถ้วน')
            }else{
                // popup('ครบ')
                const formdata = new FormData();
                const formdata_card = new FormData();
                // id card
                formdata_card.append('id_card', idcard);
                formdata_card.append('file', file_pic_idcard);
                // formdata_card.append('phone_number', phone_number);
    
                // bank 
                formdata.append('file', file_pic_bookbank);
                formdata.append('bookbank_no', bookbank_no);
                formdata.append('bank_name', bank_name);
                formdata.append('bank_person_name', bank_person_name);
                formdata.append('phone', phone);
                try {
                    await POST('/user/update_idcard', formdata_card, true);
                    await POST('/user/update_bank', formdata, true);
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'verification success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    window.location.assign('/verification')
                } catch (error) {
                    popup(error)
                }
            }
            

        }


    }
    onDropPicture = (e) => {
        if (e.target.files || e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = e => {
                this.setState({ preview_file_pic_idcard: e.target.result })
            };
            reader.readAsDataURL(e.target.files[0]);
            this.setState({ file_pic_idcard: e.target.files[0] })
        }
    }
    onDropPicturebookbank = (e) => {
        if (e.target.files || e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = e => {
                this.setState({ preview_file_pic_bookbank: e.target.result })
            };
            reader.readAsDataURL(e.target.files[0]);
            this.setState({ file_pic_bookbank: e.target.files[0] })
        }
    }

    render() {
        // console.log('verify', this.state?.verify_info?.verify)
        if (this.state?.verify_info?.verify === 0 || this.state?.verify_info?.verify === 3) {
            return (
                <div>
                    <div className="dashboard-container">
                        <div className="header">
                            <div className="background">
                                <img src={require('../../../images/dashboard-bg.png')} alt="" />
                            </div>
                            <div className="title">

                            </div>
                        </div>
                        <div className="content">
                            <div className="panel _shadow _radius w-100 h-100 ">

                                <Row >
                                    <strong >
                                        แบบฟอร์มการยืนยันตัวตน
                                  </strong>

                                </Row>
                                <Row style={{ marginTop: '30px' }}>

                                    <Col xl={5} md={5}>
                                        {/* บัตรประชาชน */}
                                        <Form.Group>
                                            <Form.Label>  บัตรประชาชน/ Passport</Form.Label>
                                            <Form.Control type="number" name="idcard" className="input-text" onChange={this.onChangeText} />
                                        </Form.Group>
                                    </Col>
                                    <Col xl={12} md={12} >
                                        <FormGroup >
                                            <Row>
                                                <Col xl={4}>
                                                    <input
                                                        // disabled={this.state.disabled}
                                                        
                                                        type="file"
                                                        onChange={this.onDropPicture}
                                                        id="inputGroupFile01"
                                                        style={{ marginTop: '30px' }}
                                                    />

                                                </Col>
                                                <Col xl={3}>
                                                    <label class="custom-file-upload2" for="inputGroupFile01" >
                                                        <img src={this.state?.preview_file_pic_idcard || require('../copytrade/copytradeimg/uploadpic.png')} alt="idcard" />
                                                    </label>
                                                </Col>
                                            </Row>


                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '30px' }}>
                                    <Col xl={4} md={4}>
                                        {/* บัญชีธนาคาร */}
                                        <Form.Group>
                                            <Form.Label>  เลขบัญชีธนาคาร</Form.Label>
                                            <Form.Control type="number" name="bookbank_no" className="input-text" onChange={this.onChangeText} />
                                            <Form.Label style={{color:'red', fontSize: 14}}>  ** ถ้าประเทศไทยให้กรอกเลขบัญชี </Form.Label>
                                            <Form.Label style={{color:'red', fontSize: 14}}>  ** ถ้าประเทศลาวให้กรอกเลข mt4 Forex4U </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={4} md={4}>
                                        {/* บัญชีธนาคาร */}
                                        <Form.Group>
                                            <Form.Label>  ชื่อบัญชี</Form.Label>
                                            <Form.Control type="text" name="bank_person_name" className="input-text" onChange={this.onChangeText} />
                                            <Form.Label style={{color:'red', fontSize: 14}}>  ** ถ้าประเทศไทยให้กรอกชื่อบัญชี </Form.Label>
                                            <Form.Label style={{color:'red', fontSize: 14}}>  ** ถ้าประเทศลาวให้กรอกชื่อ mt4 Forex4U </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={3} md={3}>
                                        {/* บัตรประชาชน */}
                                        <Form.Group>
                                            <Form.Label>  ชื่อธนาคาร</Form.Label>
                                            <Form.Control as="select" custom name={'bank_name'}
                                                // value={this.state.broker?.broker_id}
                                                onChange={this.onChangeText}
                                            >
                                                {/* {console.log('broker',broker)} */}
                                                <option name="">กรุณาเลือก</option>
                                                <option name="bank_name"> ธนาคารกรุงเทพ(BBL)</option>
                                                <option name="bank_name"> ธนาคารกรุงไทย(KTB)</option>
                                                <option name="bank_name"> ธนาคารกรุงศรีอยุธยา</option>
                                                <option name="bank_name"> ธนาคารกสิกรไทย (KBANK)</option>
                                                <option name="bank_name"> ธนาคารไทยพาณิชย์ (SCB)</option>
                                                <option name="bank_name"> ธนาคารยูโอบี (UOB)</option>
                                                <option name="bank_name"> ธนาคารทหารไทย(TMB)</option>
                                                <option name="bank_name"> ธนาคารธนชาต(TNC)</option>
                                                <option name="bank_name"> ธนาคารเกียรตินาคิน</option>
                                                <option name="bank_name"> ธนาคารทิสโก้</option>
                                                <option name="bank_name"> ธนาคารแลนด์แอนด์เฮ้าส์</option>
                                                <option name="bank_name"> ธนาคารสแตนดาร์ดชาร์เตอร์ด</option>
                                                <option name="bank_name"> ธนาคารไอซีบีซี(ไทย)</option>
                                                <option name="bank_name"> MT4 Forex4U(สำหรับประเทศลาว)</option>

                                            </Form.Control>
                                            {/* <Form.Control type="text" name="bank_name" className="input-text" onChange={this.onChangeText} /> */}
                                        </Form.Group>
                                    </Col>
                                    <Col xl={12} md={12} >
                                        <FormGroup >
                                            <Row>
                                                <Col xl={4}>
                                                    <input
                                                        style={{ marginTop: '30px' }}
                                                        // disabled={this.state.disabled}
                                                        type="file"
                                                        onChange={this.onDropPicturebookbank}
                                                        id="inputGroupFile01"
                                                    />
                                                </Col>
                                                <Col xl={3}>
                                                    <label class="custom-file-upload2" for="inputGroupFile01" >
                                                        <img src={this.state?.preview_file_pic_bookbank || require('../copytrade/copytradeimg/uploadpic.png')} alt="bookbank_no" />
                                                    </label>
                                                </Col>

                                            </Row>


                                        </FormGroup>
                                    </Col>
                                    <Col xl={5} md={5}>

                                        <Form.Group>
                                            <Form.Label>  เบอร์โทรศัพท์</Form.Label>
                                            <Form.Control type="number" name="phone" className="input-text" onChange={this.onChangeText} />
                                        </Form.Group>
                                    </Col>


                                </Row>

                                <Row  >
                                    <Col xl={5} md={5}>
                                        <input
                                            type="checkbox"
                                            name="checkverification"
                                            checked={this.state.checkverification}
                                            onChange={this.handleInputChange}
                                            style={{ marginRight: '10px', marginTop: '10px', marginBottom: '10px' }}
                                        />
                               ยืนยันว่าเป็นข้อมูลจริง
                                    </Col>

                                </Row>
                                {/* {this.state.disable } */}
                                <Row style={{ margin: '5px' }}>
                                    <button className="btn btn-orange" onClick={this.onsave}>ยืนยันข้อมูล</button>
                                </Row>

                            </div>
                        </div>

                    </div>



                </div>
            )

        } else if (this.state?.verify_info?.verify === 1 || this.state?.verify_info?.verify === 2) {
            return (
                <div>
                    <div className="dashboard-container">
                        <div className="header">
                            <div className="background">
                                <img src={require('../../../images/dashboard-bg.png')} alt="" />
                            </div>
                            <div className="title">

                            </div>
                        </div>
                        <div className="content">

                            <div className="panel _shadow _radius w-100 h-100 ">
                                {this.state?.verify_info?.verify === 2 &&
                                    <div className="text-danger mb-3">
                                        รอการยอมรับจาก Admin
                            </div>
                                }
                                <div >
                                    <strong >
                                        ข้อมูลการยืนยันตัวตน
                                  </strong>


                                </div>
                                <Row>
                                    <Col xl={6}>
                                        <span>     บัตรประชาชน  </span> &nbsp;
                                        <span className="text-gray">{this.state?.verify_info?.id_card}</span>


                                    </Col>
                                    <Col xl={6}>
                                        <span> เลขที่บัญชีธนาคาร</span>&nbsp;
                                        <span className="text-gray">{this.state?.verify_info?.bookbank_no}</span>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6}>
                                        <span>  ชื่อบัญชี</span>&nbsp;
                                        <span className="text-gray">{this.state?.verify_info?.bank_person_name}</span>


                                    </Col>
                                    <Col xl={6}>
                                        <span>       ชื่อธนาคาร</span>&nbsp;
                                        <span className="text-gray">{this.state?.verify_info?.bank_name}</span>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        รูปบัตรประชาชน
                                      </Col>
                                    <Col xl={12}>
                                        <div className="verification_img">      <img src={this.state?.verify_info?.id_card_img || require('../copytrade/copytradeimg/picture.png')} alt="idcard" /></div>

                                    </Col>

                                </Row>
                                <Row>

                                    <Col xl={12}>
                                        รูปบัญชีธนาคาร
                                 </Col>
                                    <Col xl={12}>
                                        <div className="verification_img">     <img src={this.state?.verify_info?.bookbank_img || require('../copytrade/copytradeimg/picture.png')} alt="idcard" /></div>

                                    </Col>
                                </Row>


                            </div>

                        </div>
                    </div>

                </div>
            )
        } else {
            return <div>

            </div>
        }

    }
}
