import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './routes/private-route';
import PublicRoute from './routes/public-route';
import { GET } from 'api';
import Swal from 'sweetalert2'
import './navigation.scss';

// import page
import { NotFound, Unauthorize } from 'containers';
import verification from 'containers/user/dashboard/verification';

import resetpassword from 'containers/user/resetpassword/resetpassword';
import promotion from 'containers/none-user/promotion/promotion';
import emailfacebook from 'containers/none-user/login/emailInput';
const Template1 = lazy(() => import('containers/template/template1'));
const Template2 = lazy(() => import('containers/template/template2'));
const Template3 = lazy(() => import('containers/template/template3'));
// none user
const Home = lazy(() => import('containers/none-user/home/home'));
const Login = lazy(() => import('containers/none-user/login/login'));
const Register = lazy(() => import('containers/none-user/register/register'));
const SocialTrade = lazy(() => import('containers/none-user/social-trade/social-trade'));
const SocialTradeDetail = lazy(() => import('containers/none-user/social-trade/social-trade-detail'));
const VPS = lazy(() => import('containers/none-user/vps'));
const EA = lazy(() => import('containers/none-user/ea/ea'));
const About = lazy(() => import('containers/none-user/about/about'));
const CashBack = lazy(() => import('containers/none-user/cash-back/cash-back'));
const Contact = lazy(() => import('containers/none-user/contact/contact'));
const Broker = lazy(() => import('containers/none-user/broker/broker'));
const DetailBroker = lazy(()=> import('containers/none-user/broker/DetailBroker'));
const AllBroker = lazy(() => import('containers/none-user/broker/AllBroker'));
const Regisinfo = lazy(() => import('containers/none-user/register/regisinfo'));
const Forgetpassword = lazy(() => import('containers/none-user/login/forgetpassword'));
const Promotion = lazy(() => import('../containers/none-user/promotion/promotion'));
const PromotionDetail = lazy(() => import('../containers/none-user/promotion/detail'));
const verifyNonUser = lazy(() => import('../containers/none-user/verify/verify'));
const EmailInput = lazy(() => import('../containers/none-user/login/emailInput'));
const ExpertAdvisorMoreDetailNon = lazy(() => import('../containers/none-user/ea/detail'));
const IndicatorNon = lazy(() => import('../containers/none-user/indicator/indicator'));
const IndicatorNonMore = lazy(() => import('../containers/none-user/indicator/detail'));
const PammNon = lazy(() => import('../containers/none-user/pamm/pamm'));
const DetailPammNon = lazy(() => import('../containers/none-user/pamm/DetailPamm'));
const FaqNon = lazy(() => import('../containers/none-user/faq/faq'));
const PolicyNon = lazy(() => import('../containers/none-user/policy'));
const EADashboardNon = lazy(() => import('../containers/none-user/ea_dashboard'));
const Course = lazy(() => import('../containers/none-user/course/Course'));

// user
const Dashboard = lazy(() => import('containers/user/dashboard/dashboard'));
const DashboardFx = lazy(() => import('containers/user/dashboard_fx/DashboardFx'));
const Courses = lazy(() => import('containers/user/courses/courses'));
const MoreDetail = lazy(() => import('containers/user/courses/moredetail'));
const CopyTrade = lazy(() => import('containers/user/copytrade/copytrade'));
const CopytradeMoreDetail = lazy(() => import('containers/user/copytrade/moredetail'));
const ExpertAdvisor = lazy(() => import('containers/user/expertadvisor/expertadvisor'));
const ExpertAdvisorMoreDetail = lazy(() => import('containers/user/expertadvisor/detail'));
const AffiliateIb = lazy(() => import('containers/user/affiliateIb/affiliateIb'));
const Resetpassword = lazy(() => import('containers/user/resetpassword/resetpassword'));
const IndicatorUser = lazy(() => import('containers/user/indicator/indicator'));
const IndicatorMoreDetail = lazy(() => import('containers/user/indicator/detail'));
const Verify = lazy(() => import('containers/user/verify/verify'));
const PammUser = lazy(() => import('containers/user/pamm/pamm'));
const PammDetailUser = lazy(() => import('containers/user/pamm/PammDetail'));
const TestTextEditor = lazy(() => import('containers/user/test/test'));
const TestEditor = lazy(() => import('containers/user/test/edit'));
const DashboardVPS = lazy(() => import('containers/user/vps/DashboardVPS'));
const CreateVPS = lazy(() => import('containers/user/vps/CreateVPS'));
const CopyTradeUserDetail = lazy(() => import('containers/user/copytrade/copyTradeUserDetail'));

// admin
const AdminLogin = lazy(() => import('containers/admin/login/login'));
const AdminDashboard = lazy(() => import('containers/admin/dashboard/dashboard'));
const AdminCopyTrade = lazy(() => import('containers/admin/copy-trade/copy-trade'));
const AdminBroker = lazy(() => import('containers/admin/broker/broker'));
const AdminBrokerDes = lazy(() => import('containers/admin/brokerDes/BrokerDes'));
const AdminAddBrokerDes = lazy(() => import('containers/admin/brokerDes/AddBrokerDes'));
const EditDesBroker = lazy(() => import('containers/admin/brokerDes/EditDesBroker'));
const AdminEA = lazy(() => import('containers/admin/ea/ea'));
const AdminReport = lazy(() => import('containers/admin/report/report'));
const AdminWithDraw = lazy(() => import('containers/admin/withdraw/withdraw'))
const AdminVideo = lazy(() => import('containers/admin/video/video'))
const AdminVerify = lazy(() => import('containers/admin/verify/verify'))
const AdminPromotion = lazy(() => import('../containers/admin/promotion/promotion'))
const AdminAddea = lazy(() => import('../containers/admin/ea/addea'))
const AdminEditea = lazy(() => import('../containers/admin/ea/editea'))
const AdminAddpromotion = lazy(() => import('../containers/admin/promotion/addpromotion'))
const AdminEditpromotion = lazy(() => import('../containers/admin/promotion/editpromotion'))
const AdminReportDetail = lazy(() => import('../containers/admin/report/reportdetail'))
const AdminUserRegis = lazy(() => import('../containers/user/userregis/user_regis'))
const AdminAddIndicator = lazy(() => import('../containers/admin/indicator/addIndicator'))
const AdminIndicator = lazy(() => import('../containers/admin/indicator/indicator'))
const AdminPamm = lazy(() => import('containers/admin/pamm/pamm'))
const AdminAddPamm  = lazy(() => import('containers/admin/pamm/addPamm'))
const AdminEditPamm  = lazy(() => import('containers/admin/pamm/editPamm'))
const AdminUserDetail  = lazy(() => import('containers/user/userregis/userDetail'))
const AdminEditVPS = lazy(() => import('containers/admin/vps/EditVPS'));
const AdminAddCopyTrade = lazy(() => import('containers/admin/copy-trade/AddCopyTrade'));
const AdminEditCopyTrade = lazy(() => import('containers/admin/copy-trade/EditDetailCopy'));
const AdminEditPolicy = lazy(() => import('containers/admin/other/EditPolicy'));
const AdminEditEADashboard = lazy(() => import('containers/admin/other/EditEADashboard'));
const AdminEditEADashboardUser = lazy(() => import('containers/admin/ea_dashboard_fx/DashboardFx'));
const AdminPackageVPS = lazy(() => import('containers/admin/vps/PackageVPS'));
const AdminAddPackageVPS = lazy(() => import('containers/admin/vps/AddPackageVPS'));
const AdminEditPackageVPS = lazy(() => import('containers/admin/vps/EditPackageVPS'));
const AdminManageVPS = lazy(() => import('containers/admin/vps/ManageVPS'));
const AdminManageVPSId = lazy(() => import('containers/admin/vps/ManageVPSId'));
const AdminEditCourse = lazy(() => import('containers/admin/other/EditCourse'));
const AdminEditCashback = lazy(() => import('containers/admin/cashback/editCashback'));


export default class NavigationRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role: '',
			load_page: true
		};
	}
	componentDidMount() {
		this.checkAuthen();
	}
	checkAuthen = async () => {
		let login = window.localStorage.getItem('login');
		if (login) {
			try {
				let result = await GET('/authen');
				this.setState({ role: result.role });
			} catch (error) {
				// console.log('login fail', error);
				Swal.fire({
					title: 'แจ้งเตือน',
					text: "เกิดข้อผิดพลาด กรุณาเข้าสู่ระบบใหม่ออกครั้ง",
					icon: 'warning',
				}).then(async (result) => {
					if (result.value) {
						await window.localStorage.setItem('login', '');
						window.location.assign('/login');
					}
				})
			}
		} else {
			this.setState({ role: 'none_user' });
		}
		this.setState({ load_page: false })
	};
	render() {
		if (this.state.load_page) {
			return <div />
		} else {
			return (
				<Router>
					<Suspense fallback={<div />}>
						{this.state.role === 'user' ? (
							<Switch>
								{/* PrivateRoute */}
								<PrivateRoute exact authed={true} role="user" component={Dashboard} path={'/'} />
								<PrivateRoute authed={true} role="user" component={Dashboard} path={'/dashboard'} />
								<PrivateRoute exact authed={true} role="user" component={Courses} path={'/courses'} />
								<PrivateRoute back authed={true} role="user" component={MoreDetail} path={'/courses/moredetail'} />
								<PrivateRoute exact authed={true} role="user" component={CopyTrade} path={'/copytrade'} />
								<PrivateRoute back authed={true} role="user" component={CopytradeMoreDetail} path={'/copytrade/moredetail'} />
								<PrivateRoute exact authed={true} role="user" component={ExpertAdvisor} path={'/expert-advisor'} />
								<PrivateRoute back exact authed={true} role="user" component={ExpertAdvisorMoreDetail} path={'/expert-advisor/moredetail/:id?'} />
								<PrivateRoute back exact authed={true} role="user" component={IndicatorMoreDetail} path={'/indicator/moredetail/:id?'} />
								<PrivateRoute exact authed={true} role="user" component={PammUser} path={'/pamm'} />
								<PrivateRoute back exact authed={true} role="user" component={PammDetailUser} path={'/detail_pamm/:id?'} />
								<PrivateRoute exact authed={true} role="user" component={AffiliateIb} path={'/affiliate'} />
								<PrivateRoute exact authed={true} role="user" component={Dashboard} path={'/verify/:token?'} />
								<PrivateRoute exact authed={true} role="user" component={IndicatorUser} path={'/indicator'} />
								<PrivateRoute exact authed={true} role="user" component={verification} path={'/verification'} />
								<PrivateRoute exact authed={true} role="user" component={DashboardFx} path={'/ea_dashboard'}/>
								<PrivateRoute exact authed={true} role="user" component={DashboardVPS} path={'/vps_dashboard'}/>
								<PrivateRoute exact authed={true} role="user" component={CreateVPS} path={'/create_vps'}/>
								<PrivateRoute back authed={true} role="user" component={CopyTradeUserDetail} path={'/social-trade-detail/:name?'} />
								

								{/* <PrivateRoute exact authed={true} role="user" component={verificationsuccess} path={'/verificationsuccess'} /> */}
								<PrivateRoute exact authed={true} role={this.state.role} component={Dashboard} path={'/'} />
								<Route path="/unauthorize" component={Unauthorize} />
								<Route path="*" component={NotFound} />
							</Switch>
						) : this.state.role === 'none_user' ? (
							<Switch>
								<PublicRoute component={Home} path={'/'} exact />
								<PublicRoute component={Home} path={'/home'} exact />
								<PublicRoute component={verifyNonUser} path={'/verify/:token?'} />
								<PublicRoute component={Login} path={'/login'} nofooter />
								<PublicRoute component={AdminLogin} path={'/admin/login'} nofooter noNavbar />
								<PublicRoute component={Register} path={'/register'} nofooter />
								<PublicRoute component={SocialTrade} path={'/social-trade'} nofooter />
								<PublicRoute component={SocialTradeDetail} path={'/social-trade-detail/:name?'} nofooter />
								<PublicRoute component={EA} path={'/ea'} nofooter />
								<PublicRoute component={About} path={'/about'} />
								<PublicRoute component={AllBroker} path={'/broker'} />
								<PublicRoute component={CashBack} path={'/cash-back'} nofooter />
								<PublicRoute component={Contact} path={'/contact'} />
								<PublicRoute component={Regisinfo} path={'/regisinfo'} nofooter />
								<PublicRoute component={Template1} path={'/template1'} />
								<PublicRoute component={Regisinfo} path={'/regitinfo'} />
								<PublicRoute component={Template2} path={'/temp2'} />
								<PublicRoute component={Template3} path={'/temp3'} />
								<PublicRoute component={CopytradeMoreDetail} path={'/copytrade/moredetail'} />
								<PublicRoute component={ExpertAdvisorMoreDetailNon} path={'/expert-advisor/moredetail/:id?'} />
								<PublicRoute component={Resetpassword} path={'/resetpassword/:token?'} />
								<PublicRoute component={Forgetpassword} path={'/forgotpassword'} />
								<PublicRoute component={Promotion} path={'/community'} />
								<PublicRoute component={Course} path={'/course'} />
								<PublicRoute component={EmailInput} path={'/emailinput'} />
								<PublicRoute component={PromotionDetail} path={'/community_detail/:id?'} nofooter />
								<PublicRoute component={IndicatorNon} path={'/indicator'} nofooter />
								<PublicRoute component={IndicatorNonMore} path={'/indicator-detail/moredetail/:id?'} />
								<PublicRoute component={FaqNon} path={'/faq'}/>
								<PublicRoute component={DetailBroker} path={'/detail_broker/:name?'}/>
								<PublicRoute component={PammNon} path={'/pamm'}/>
								<PublicRoute component={DetailPammNon} path={'/detail_pamm/:id?'}/>
								<PublicRoute component={TestTextEditor} path={'/test'}/>
								<PublicRoute component={TestEditor} path={'/edit'} nofooter noNavbar/>
								<PublicRoute component={VPS} path={'/vps'}/>
								<PublicRoute component={PolicyNon} path={'/policy'}/>
								<PublicRoute component={EADashboardNon} path={'/ea_dashboard'}/>
								
								{/* <PublicRoute component={Broker} path={'/fx4u'} nofooter/> */}
								
								<Route path="/unauthorize" component={Unauthorize} />
								<Route path="*" component={NotFound} />
							</Switch>
						) : this.state.role === 'admin' ? (
							<Switch>
								<PrivateRoute exact authed={true} role="admin" component={AdminCopyTrade} path={'/'} />
								<PrivateRoute authed={true} role="admin" component={AdminDashboard} path={'/dashboard'} />
								<PrivateRoute authed={true} role="admin" component={AdminCopyTrade} path={'/copytrade'} />
								<PrivateRoute authed={true} role="admin" component={AdminBroker} path={'/broker'} />
								<PrivateRoute authed={true} role="admin" component={AdminUserRegis} path={'/user-regis'} />
								<PrivateRoute authed={true} role="admin" component={AdminEA} path={'/expert-advisor'} />
								<PrivateRoute authed={true} role="admin" component={AdminReport} path={'/report'} />
								<PrivateRoute authed={true} role='admin' component={AdminWithDraw} path={'/withdraw'} />
								<PrivateRoute authed={true} role='admin' component={AdminVideo} path={'/video'} />
								<PrivateRoute authed={true} role='admin' component={AdminVerify} path={'/verify'} />
								<PrivateRoute authed={true} role='admin' component={AdminPromotion} path={'/community'} />
								<PrivateRoute authed={true} role='admin' component={AdminAddea} path={'/addea'} />
								<PrivateRoute authed={true} role='admin' component={AdminAddpromotion} path={'/addpromotion'} />
								<PrivateRoute authed={true} role='admin' component={AdminEditpromotion} path={'/editpromotion'} />
								<PrivateRoute authed={true} role='admin' component={AdminAddIndicator} path={'/addindicator'} />
								<PrivateRoute authed={true} role='admin' component={AdminEditea} path={'/editea'} />
								<PrivateRoute authed={true} role='admin' component={AdminReportDetail} path={'/reportdetail'} />
								<PrivateRoute authed={true} role='admin' component={AdminIndicator} path={'/indicator'} />
								<PrivateRoute authed={true} role='admin' component={AdminBrokerDes} path={'/broker_des'}/>
								<PrivateRoute authed={true} role='admin' component={AdminAddBrokerDes} path={'/add_broker_des'}/>
								<PrivateRoute authed={true} role='admin' component={EditDesBroker} path={'/edit_broker_des/:name'}/>
								<PrivateRoute authed={true} role='admin' component={AdminPamm} path={'/pamm'}/>
								<PrivateRoute authed={true} role='admin' component={AdminAddPamm} path={'/addPamm'}/>
								<PrivateRoute authed={true} role='admin' component={AdminEditPamm} path={'/editPamm'}/>
								<PrivateRoute authed={true} role='admin' component={AdminUserDetail} path={'/userDetail/:id'}/>
								<PrivateRoute authed={true} role='admin' component={AdminEditVPS} path={'/vps'}/>
								<PrivateRoute authed={true} role='admin' component={AdminAddCopyTrade} path={'/addcopy'}/>
								<PrivateRoute authed={true} role='admin' component={AdminEditCopyTrade} path={'/editCopy'}/>
								<PrivateRoute authed={true} role='admin' component={AdminEditPolicy} path={'/editpolicy'}/>
								<PrivateRoute authed={true} role='admin' component={AdminEditEADashboard} path={'/edit_ea_dashboard'}/>
								<PrivateRoute authed={true} role='admin' component={AdminEditEADashboardUser} path={'/ea_dashboard_fx'}/>
								<PrivateRoute authed={true} role='admin' component={AdminPackageVPS} path={'/package_vps'}/>
								<PrivateRoute authed={true} role='admin' component={AdminAddPackageVPS} path={'/add_package_vps'}/>
								<PrivateRoute authed={true} role='admin' component={AdminEditPackageVPS} path={'/edit_package_vps/:id'}/>
								<PrivateRoute authed={true} role='admin' component={AdminManageVPS} path={'/manage_vps'}/>
								<PrivateRoute authed={true} role='admin' component={AdminManageVPSId} path={'/manage_vps_by_id/:id'}/>
								<PrivateRoute authed={true} role='admin' component={AdminEditCourse} path={'/edit_course'}/>
								<PrivateRoute authed={true} role="admin" component={AdminEditCashback} path={'/edit_cashback'} />
								
								
								<Route path="/unauthorize" component={Unauthorize} />
								<Route path="*" component={NotFound} />
							</Switch>
						) : (
										<div />
									)}
					</Suspense>
				</Router>
			)
		}
	}
}
