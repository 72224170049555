import React, { Component } from 'react'

export default class NotFound extends Component {
    render() {
        return (
            <div>
                <div>
                    <h4>404</h4>
                    <div>ไม่พบ url นี้</div>
                </div>
            </div>
        )
    }
}
