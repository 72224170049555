import React, { Component } from 'react'
import '../../../styles/contact.scss'
import { Form, Row, Col } from 'react-bootstrap'
import { POST, GET } from 'api';
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from 'react-router-dom';
import { params } from 'utils';
import moment from 'moment'
class promotion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newpage: [],
            promotion: [],
            page: 1
        }


    }
    componentDidMount() {
        this.getPromotion();
    }
    getPromotion = async () => {
        try {
            let result = await GET('/promotion/user');
            // console.log('result', result);
            this.setState({ promotion: result, newpage: result });
            this.onChangePage('t', 1)
        } catch (error) {

            // console.log('getcopytrade error', error);
        }
    };
    onChangePage = (e, value) => {
        this.setState({ page: value })

    }
    moredetail = (promotion_id) => {
        // let copy_trade_list=this.state;
        this.props.history.push(`/community_detail/${promotion_id}` + params);

    }

    render() {
        let { page, promotion } = this.state
        let pages = page - 1
        let detailpage = promotion.slice(pages * 9, page * 9)
        let count = Math.ceil(promotion.length > 0 && promotion.length / 9)
        return (
            <div>
                <div className="contact header_content_promotion">
                    <div className="header" style={{ marginBottom: '0' }}></div>
                    <div className="container mt-5">
                        <div className="d-flex justify-content-center" style={{ fontSize: '40px' }}>
                            {/* <strong className="text-orange"></strong> */}
                            <strong className="text-blue">Community</strong>
                        </div>
                        {detailpage?.map((e, i) => (
                            <Row xs={12} style={{ margin: '40px' }}>


                                <Col xs={12} md={6} xl={6}>
                                    <div className="panel _shadow _radius more-detail" onClick={() => this.moredetail(e.promotion_id)}>

                                        <div ><img src={e?.image} alt="promotion1" style={{ height: '300px' }} /></div>


                                    </div>
                                </Col>

                                <Col xs={12} md={6} xl={6}>
                                    <h2 className="text-orange">

                                        {e?.title}
                                    </h2>
                                    <h5 className="text-blue">   เขียนเมื่อ : {moment(e.create_at).format('LLLL')}</h5>
                                    <a className="promotion_detail_a " onClick={() => this.moredetail(e.promotion_id)}>คลิ๊กเลย!!</a>
                                </Col>

                            </Row>
                        ))}
                        {/* 
                        <Pagination
                            style={{ margin: '10px' }}
                            page={this.state.page}
                            count={count}
                            className="pagination-responsive"
                            onChange={this.onChangePage}
                        ></Pagination> */}

                    </div>

                </div>
            </div>



        )
    }
}
export default withRouter(promotion)