import React, { useState, useEffect } from 'react'
import { useGoogleLogout } from 'react-google-login'
import { useHistory } from 'react-router-dom';
import { NavLink, params } from 'utils'
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Footer from '../footer/footer'
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
// icons
import ShopIcon from '@material-ui/icons/Shop';
import SaveIcon from "@material-ui/icons/Save";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import ShowChartIcon from "@material-ui/icons/ShowChart"
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AssignmentIcon from '@material-ui/icons/Assignment';
import MenuIcon from '@material-ui/icons/Menu';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import './navbar.scss'
import './sidebar.scss'
import { POST, GET } from 'api';
import moment from 'moment';
import 'moment/locale/th';
import socketIOClient from 'socket.io-client'
import verification from 'containers/user/dashboard/verification';

export default function Navbar(props) {
    const history = useHistory();
    const [show_menu, setShow_menu] = useState(false)
    const [show_noti, setShow_noti] = useState(false)
    const [profile, setProfile] = useState(JSON.parse(window.localStorage.getItem('profile')))
    const [noti, setNoti] = useState([])
    const [notiCount, setCountNoti] = useState(0)
    const [verify, setverify] = useState([])
    // console.log('profile',profile)
    const logout = async () => {
        try {
            // alert('logout')
            await GET('/authen/logout')
            console.log('logout')
            window.localStorage.setItem('login', '')
            window.location.assign('/')
        } catch (error) {

        }

        // signOut
    }


    const onverification = async () => {

        window.location.assign('/verification')



    }
    useEffect(() => {
        // Update the document title using the browser API
        async function getNoti() {
            if (profile && props.role === 'user') {
                let noti = await POST('/noti/my', { user_id: profile.user_id })
                setNoti(noti)
                // console.log('noti ', noti)
            }

        }
        getNoti()
    }, []);

    useEffect(() => {
        // Update the document title using the browser API
        async function countNoti() {
            if (profile && props.role === 'user') {
                try {
                    let result = await GET('/noti/count');
                    setCountNoti(result.count)
                    // alert(result.count)
                } catch (error) {
                    console.log('err', error)
                }


            }
        }
        countNoti()
    }, []);

    useEffect(() => {
        if (profile && props.role === 'user') {
            function response() {
                // console.log(process.env.REACT_APP_API_PATH + '/noti')
                const socket = socketIOClient(process.env.REACT_APP_API_SOCKET + '/noti')
                // console.log('socket ', socket)
                socket.on('new-message', (messageNew) => {
                    // console.log("messageNew ", messageNew)
                    // console.log("socketRes ", socket)
                    // console.log("messageF ", noti)
                    // noti.push(messageNew)
                    setNoti(messageNew)
                    // console.log("get ", noti)
                })
            }
            response()
        }
    }, [])


    async function readNoti(noti_id) {
        // alert(noti_id)
        try {
            await POST('/noti/read_noti', { noti_id: noti_id })
            getNoti()
            countNoti()
            // console.log('readNoti ', readNoti)
            // Swal.fire('Verify success', '', 'success')
        } catch (error) {
            Swal.fire('เกิดข้อผิดพลาด', error, 'error')
        }
    }

    async function getNoti() {
        if (profile && props.role === 'user') {
            let noti = await POST('/noti/my', { user_id: profile.user_id })
            setNoti(noti)
            // console.log('noti ', noti)
        }

    }

    async function countNoti() {
        if (profile && props.role === 'user') {
            try {
                let result = await GET('/noti/count');
                setCountNoti(result.count)
                // alert(result.count)
            } catch (error) {
                console.log('err', error)
            }


        }
    }


    if (props.role === 'user') {
        return (
            <React.Fragment>
                <div className="sidebar">
                    <div className="logo">
                        <img src={require('./images/society-01@2x.png')} alt="logo" />
                    </div>
                    <ul className="menu">
                        <li className="link">
                            <NavLink to="/" classActive="active" tag="/dashboard"><DashboardIcon />Dashboard</NavLink>
                        </li>
                        {/* <li className="link">
                            <NavLink to="/courses" classActive="active" tag="/courses"><VideoLibraryIcon />Course</NavLink>
                        </li> */}
                        <li className="link">
                            <NavLink to="/copytrade" classActive="active" tag="/copytrade"><FileCopyIcon />Copy Trade</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/expert-advisor" classActive="active" tag="/expert-advisor"><SaveIcon />EA</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/indicator" classActive="active" tag="/indicator"><TrendingUpIcon />Indicator</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/ea_dashboard" classActive="active" tag="/ea_dashboard"><TrendingUpIcon />Dashboard Fx</NavLink>
                        </li>
                        {/* <li className="link">
                            <NavLink to="/signal" classActive="active" tag="/signal"><TrendingUpIcon />Signal</NavLink>
                        </li> */}
                        <li className="link">
                            <NavLink to="/affiliate" classActive="active" tag="/affiliate"><RecordVoiceOverIcon />Affiliate & Cashback</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/pamm" classActive="active" tag="/pamm"><ShowChartIcon />Pamm</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/vps_dashboard" classActive="active" tag="/vps_dashboard"><ShowChartIcon />VPS</NavLink>
                        </li>
                        {/* <li className="link">
                            <NavLink to="/support" classActive="active" tag="/support"><LiveHelpIcon />Support</NavLink>
                        </li>   */}
                        <li className="link">
                            <a onClick={() => logout()} style={{ marginTop: '100px' }}><ExitToAppIcon />Logout</a>
                        </li>
                        {/* <li className="link">
                            <NavLink onClick={() => logout()}><ExitToAppIcon />Logout</NavLink>
                        </li> */}
                    </ul>
                </div>
                <div className="user_container">
                    <div className="navbar user">
                        <div className="-left">
                            {props.back && <button className="btn btn-green" onClick={() => history.goBack()}>กลับ</button>}
                            <div className="-icon">
                                <img src={require('../../images/logo.png')} alt="" />
                            </div>
                        </div>
                        <div className="-right">


                            <div className="-user" >
                                <span className="-name">{profile?.user_name}</span>
                                <div className="-pic">
                                    <img src={profile?.img_url || require('./images/Group 547@2x.png')} alt="" />
                                </div>

                            </div>

                            <button className="menu_icon" onClick={() => setShow_menu(!show_menu)}>
                                <MenuIcon></MenuIcon>

                            </button>

                            <DropdownButton
                                alignRight
                                className="btn-dropdown"
                                id="dropdown-menu-align-right"
                            >

                                <Dropdown.Item eventKey="2" onClick={() => onverification()}>Verification</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={() => logout()}>Logout</Dropdown.Item>


                            </DropdownButton>
                            <div className="-help"><HelpOutlineIcon fontSize="large" /></div>
                            <div className="-noti d-flex">


                                <Button onClick={() => setShow_noti(!show_noti)}>

                                    <NotificationsNoneIcon />
                                    {/* {console.log('noti', noti)} */}
                                    {notiCount > 0 ? <div className="noti-alert" >{notiCount > 99 ? '99+' : notiCount}</div> : <div ></div>}

                                </Button>
                                <ul className={`-list ${show_noti ? 'd-block' : 'd-none'}`}>
                                    {/* {console.log('show_noti', show_noti)} */}
                                    {noti.map((e, i) =>
                                        <li onClick={() => readNoti(e.noti_id)} className='hover-noti'>

                                            <div className="-title">{e.title}</div>
                                            <div className="-detail">{e.detail}</div>
                                            <div className="d-flex">
                                                <div className="-time">{moment(e.create_at).add(543, 'year').format('DD MMMM YYYY เวลา HH:mm น.')}</div>
                                                {e.status === 1 && <div className="noti-alert-list">
                                                </div>}
                                            </div>

                                        </li>
                                    )}

                                    {/* <li>
                                        <div className="-title">ฝากเงิน</div>
                                        <div className="-detail">คุณได้ถอนเงิน</div>
                                        <div className="-time">29/10/2020</div>
                                    </li>
                                    <li>
                                        <div className="-title">เงินเข้า</div>
                                        <div className="-detail">คุณได้ถอนเงิน</div>
                                        <div className="-time">29/10/2020</div>
                                    </li> */}
                                </ul>
                            </div>


                        </div>
                    </div>
                    <ul className={`user_menu ${show_menu ? 'show' : 'hidden'}`}>
                        <li className="link">
                            <NavLink to="/" classActive="active" tag="/dashboard"><DashboardIcon />Dashboard</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/copytrade" classActive="active" tag="/copytrade"><FileCopyIcon />Copy Trade</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/expert-advisor" classActive="active" tag="/expert-advisor"><SaveIcon />EA</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/indicator" classActive="active" tag="/Indicator"><TrendingUpIcon />Indicator</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/ea_dashboard" classActive="active" tag="/ea_dashboard"><TrendingUpIcon />Dashboard fx</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/vps_dashboard" classActive="active" tag="/vps_dashboard"><TrendingUpIcon />VPS</NavLink>
                        </li>

                        {/* <li className="link">
                            <NavLink to="/courses" classActive="active" tag="/courses"><VideoLibraryIcon />Course</NavLink>
                        </li> */}
                        {/* <li className="link">
                            <NavLink to="/copytrade" classActive="active" tag="/copytrade"><FileCopyIcon />Copy Trade</NavLink>
                        </li> */}
                        {/* <li className="link">
                            <NavLink to="/expert-advisor" classActive="active" tag="/expert-advisor"><SaveIcon />EA</NavLink>
                        </li> */}
                        {/* <li className="link">
                            <NavLink to="/signal" classActive="active" tag="/signal"><TrendingUpIcon />Signal</NavLink>
                        </li> */}
                        <li className="link">
                            <NavLink to="/affiliate" classActive="active" tag="/affiliate"><RecordVoiceOverIcon />Affiliate & Cashback</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/pamm" classActive="active" tag="/pamm"><ShowChartIcon />Pamm</NavLink>
                        </li>
                        {/* <li className="link">
                            <NavLink to="/support" classActive="active" tag="/support"><LiveHelpIcon />Support</NavLink>
                        </li> */}
                        <li className="link">
                            <a onClick={() => logout()} ><ExitToAppIcon />Logout</a>
                        </li>
                    </ul>
                    {props.children}
                </div>
            </React.Fragment>
        )
    } else if (props.role === 'admin') {
        return (
            <React.Fragment>
                <div className="sidebar">
                    <div className="logo">
                        <img src={require('./images/society-01@2x.png')} alt="logo" />
                    </div>
                    <ul className="menu">
                        <li className="link">
                            <NavLink to="/dashboard" classActive="active" tag="/dashboard"><DashboardIcon />Dashboard</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/copytrade" classActive="active" tag="/copytrade"><FileCopyIcon />Copy Trade</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/expert-advisor" classActive="active" tag="/expert-advisor"><SaveIcon />EA</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/indicator" classActive="active" tag="/indicator"><TrendingUpIcon />Indicator</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/user-regis" classActive="active" tag="/user-regis"><SaveIcon />User Register</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/broker" classActive="active" tag="/broker"><RecordVoiceOverIcon />Broker</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/report" classActive="active" tag="/report"><AssignmentIcon />Report</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/ea_dashboard_fx" classActive="active" tag="/ea_dashboard_fx"><ShopIcon />User Dashboard</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/withdraw" classActive="active" tag="/withdraw"><MoneyOffIcon />Withdraw</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/video" classActive="active" tag="/video"><VideocamIcon />Video</NavLink>
                        </li>
                        {/* <li className="link">
                            <NavLink to="/package_vps" classActive="active" tag="/package_vps"><VideocamIcon />Package VPS</NavLink>
                        </li> */}
                        <li className="link">
                            <NavLink to="/manage_vps" classActive="active" tag="/manage_vps"><VideocamIcon />จัดการ VPS</NavLink>
                        </li>
                        
                        {/* <li className="link">
                            <NavLink to="/verify" classActive="active" tag="/verify"><VerifiedUserIcon />ยืนยันตัวตน</NavLink>
                        </li> */}
                        {/* <li className="link">
                            <NavLink to="/profitsharing" classActive="active" tag="/broker_des"><RecordVoiceOverIcon />การชำระเงิน EA</NavLink>
                        </li> */}
                        <li className="link">
                            <NavLink to="/community" classActive="active" tag="/community"><ShopIcon />Community</NavLink>
                        </li>
                        {/* <li className="link">
                            <NavLink to="/broker_des" classActive="active" tag="/broker_des"><RecordVoiceOverIcon />ข้อมูลโบรคเกอร์</NavLink>
                        </li> */}
                        <li className="link">
                            <NavLink to="/pamm" classActive="active" tag="/pamm"><ShowChartIcon />Pamm</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/vps" classActive="active" tag="/vps"><ShowChartIcon />VPS</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/editpolicy" classActive="active" tag="/vps"><ShopIcon />Policy</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/edit_course" classActive="active" tag="/edit_course"><ShopIcon />Course</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/edit_cashback" classActive="active" tag="/edit_cashback"><ShopIcon />Cashback</NavLink>
                        </li>
                        <li className="link">
                            <NavLink to="/edit_ea_dashboard" classActive="active" tag="/edit_ea_dashboard"><ShopIcon />Dashboard FX</NavLink>
                        </li>
                        <li className="link">
                            <a href="" onClick={() => logout()}><ExitToAppIcon />Logout</a>
                        </li>
                    </ul>
                </div>
                <div className="admin_container">
                    <div className="navbar admin">
                        <div className="-left">
                            {props.back && <button className="btn btn-green" onClick={() => history.goBack()}>กลับ</button>}
                            <div className="-icon">
                                <img src={require('../../images/logo.png')} alt="" />
                            </div>
                        </div>
                        <div className="-right">
                            <div className="-user" onClick={() => logout()}>
                                <span className="-name">Admin Controler</span>
                                <div className="-pic">
                                    <img src={require('./images/Group 547@2x.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.children}
                </div>
            </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>
                <div style={{ position: 'relative' }}>
                    <div className="navbar none_user">
                        <div className="-left">
                            <div className="logo">
                            <   a href={"/home" + params}><img src={require('../../images/logo.png')} alt="" /></a>
                            </div>
                            <button className="menu_icon" onClick={() => setShow_menu(!show_menu)}>
                                <MenuIcon />
                            </button>
                        </div>
                        <div className={`-right ${show_menu ? 'show' : 'hidden'}`}>
                            <ul className="menu">
                                {/* <li className="link">
                                    <NavLink to={"/home" + params} classActive="active" tag="home">HOME</NavLink>
                                </li> */}
                                <li className="link">
                                    <NavLink to={"/about" + params} classActive="active" tag="/about">ABOUT</NavLink>
                                </li>
                                {/* <li className="link">
                                    <NavLink to={"/social-trade" + params} classActive="active" tag="/social-trade">SOCIAL TRADE</NavLink>
                                </li> */}
                                <li className="link">
                                    <NavLink to={"/broker" + params} classActive="active" tag="/broker">BROKER</NavLink>
                                </li>
                                {/* <li className="link">
                                    <NavLink to={"/ea" + params} classActive="active" tag="/ea">EA</NavLink>
                                </li> */}
                                {/* <li className="link"> */}
                                    <li className="dropdown">TRADING TOOLS
                                        {/* <NavLink tag="/tools" classActive="active" className="link"> TOOLS</NavLink> */}
                                        <div className="dropdown-content">
                                            <NavLink to={"/ea_dashboard" + params} >DASHBOARD FX</NavLink>
                                            <NavLink to={"/social-trade" + params} >COPY TRADE</NavLink>
                                            <NavLink to={"/ea" + params} >EA</NavLink>
                                            <NavLink to={"/indicator" + params} >INDICATOR</NavLink>
                                            <NavLink to={"/pamm" + params} >PAMM</NavLink>
                                            <NavLink to={"/vps" + params} >VPS</NavLink>
                                            
                                        </div>
                                    </li>
                                {/* </li> */}


                                <li className="link">
                                    <NavLink to={"/cash-back" + params} classActive="active" tag="/cash-back">CASH BACK</NavLink>
                                </li>
                                {/* <li className="link">
                                    <NavLink to="/contact" classActive="active" tag="/contact">CONTACT</NavLink>
                                </li> */}
                                 <li className="dropdown">COMMUNITY
                                        {/* <NavLink tag="/tools" classActive="active" className="link"> TOOLS</NavLink> */}
                                        <div className="dropdown-content">
                                            <NavLink to={"/faq" + params} >FAQ</NavLink>
                                            <NavLink to={"/community" + params} >COMMUNITY</NavLink>
                                            <NavLink to={"/course" + params} >COURSE</NavLink>
                                        </div>
                                    </li>
                                {/* <li className="link">
                                    <NavLink to={"/promotion" + params} classActive="active" tag="/promotion">COMMUNITY</NavLink>
                                </li> */}
                            </ul>
                            <div>
                                <button
                                    className="btn btn-orange btn-radius btn-bigger"
                                    onClick={() => window.location.assign('/login' + params)}>Register / Login</button>
                            </div>
                        </div>
                    </div>
                    {props.children}
                    {!props.footer && <Footer />}
                </div>
            </React.Fragment>
        )
    }
}
