export const path = process.env.REACT_APP_API_PATH;

function response(json) {
	return json.success
		? Promise.resolve(json.result)
		: Promise.reject(json.message);
}

export const POST = (url, data, formData) =>
	fetch(path + url, {
		method: 'POST',
		headers: formData
			? {}
			: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
		credentials: 'include',
		body: formData ? data : JSON.stringify(data)
	})
		.then((res) => res.json())
		.then(response);

export const GET = (url) =>
	fetch(path + url, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		credentials: 'include'
	})
		.then((res) => res.json())
		.then(response);
