import React from "react";
import { Route, Redirect } from 'react-router-dom'
import { Navbar } from 'components'

const PrivateRoute = ({
    component: Component,
    authed,
    role,
    back,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props =>
                (authed && role === 'user') ?
                    (
                        <Navbar role={role} back={back}>
                            <Component {...props} />
                        </Navbar>
                    ) : (authed && role === 'admin') ? (
                        <Navbar role={role}>
                            <Component {...props} />
                        </Navbar>
                    ) : (<Redirect to={"/"} />)
            }
        />
    );
};

export default PrivateRoute;